
	import { Component, Vue } from "vue-property-decorator";
	import FloorSelector from "../../components/floorSelector/floorSelector.vue";
	import request from "@/request";
	import Hls from "hls.js";
	import Loading from "@/components/loading/Loading.vue";
	import { Page, Table, TableColumn } from "view-design";
	import { Route } from "vue-router";

	@Component({
		components: {
			FloorSelector,
			Loading,
			Table,
			Page
		}
	})
	export default class SmtMain extends Vue {
		floors:{ name:string, id:number }[] = [];
		loading:boolean = true;
		data:any[] = [];
		tableLoading:boolean = true;
		page:number = 1;
		total:number = 0;
		readonly columns:TableColumn[] = [
			{
				title: "编号",
				key: "code",
				width: 150
			},
			{
				title: "品牌",
				key: "brand"
			},
			{
				title: "类型",
				key: "type"
			},
			{
				title: "在线状态",
				key: "status"
			}
		];
		private readonly id:string = "id" + Math.ceil(Math.random() * 1000);
		private hls?:Hls;

		get height ():number {
			return document.getElementsByClassName("mainContent")[ 0 ] ? document.getElementsByClassName("index")[ 0 ].clientHeight - 490 : 400;
		}

		pageChange (page:number = 1) {
			this.tableLoading = !0;
			this.page = page;
			this.data = [];
			request({
				url: "/smt/rest/camera/",
				params: {
					floor: this.$store.state.floorValue,
					pagesize: Math.floor((this.height - 40) / 50),
					page
				}
			}).then(({ data }) => {
				this.data = data.results.map((t:any) => ({
					code: t.code,
					name: t.name,
					brand: t.ctype ? t.ctype.brand : "",
					type: t.ctype ? t.ctype.name : "",
					status: [ "离线", "在线" ][ t.onLineStatus ],
					id: t.id,
					cameraUuid: t.cameraUuid,
					floor: t.floor ? t.floor.match(/\/\d+\//)[ 0 ].replace(/\//g, "") : "",
					guid: t.guid
				}));
				this.total = data.count;
				this.tableLoading = !1;
			});
		}

		onSelect (value:any) {
			this.$store.commit("updateLiveStream", [ value.id, value.cameraUuid ].join());
			if (value.floor) {
				this.$store.commit("findFloorAndCamera", [ value.floor, value.guid ]);
			}
		}

		mounted () {
			if (this.$store.state.showType !== "room") {
				this.$store.commit("changeViewByCode", { type: "room", id: "" });
			}
			this.$store.commit("updateCameraStatus", true);
			request({
				url: "/space/floor/",
				params: {
					facility: 1
				}
			}).then(({ data }) => {
				this.floors = data.reverse().map((t:{ name:string, id:string }) => ({
					name: t.name,
					id: t.id
				}));
			});
			// @ts-ignore
			const video:HTMLVideoElement = document.getElementById(this.id);
			this.listeners = this.$store.subscribe((mutation) => {
				if (mutation.type === "updateLiveStream") {
					if (this.hls) {
						this.hls.stopLoad();
						this.hls.detachMedia();
					}
					const hls = new Hls();
					hls.loadSource(`/mag/hls/${ mutation.payload.split(",")[ 1 ] }/1/live.m3u8`);
					hls.attachMedia(video);
					this.loading = true;
					hls.on(Hls.Events.MANIFEST_PARSED, () => {
						video.play();
						this.loading = false;
					});
					this.hls = hls;
				} else if (mutation.type === "changeViewByCode") {
					this.pageChange();
				}
			});
			if (Hls.isSupported()) {
				const hls = new Hls();
				this.hls = hls;
				hls.loadSource("/mag/hls/6136737bc3c2486f910d94f8292bbb72/1/live.m3u8");
				hls.attachMedia(video);
				hls.on(Hls.Events.MANIFEST_PARSED, () => {
					video.play();
					this.loading = false;
				});
			}
			this.pageChange();
		}

		beforeRouteLeave (to:Route, from:Route, next:Function) {
			this.listeners();
			if (this.hls) {
				this.hls.stopLoad();
				this.hls.detachMedia();
				this.hls.destroy();
			}
			this.$store.commit("updateCameraStatus", false);
			next();
		}

		private listeners () {
		}
	}

